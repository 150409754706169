<template>
    <div class="mb-4">
        <div class="row justify-content-start mb-4">
            <div class="col-4">
                <p class="font-size-h6">
                    {{ $t("OFFENDER.DETAILS.CITIZENSHIP.HEADER") }}
                </p>
                <b-form-radio-group
                    size="lg"
                    v-model="selectedCitizenship"
                    :options="citizenship"
                    name="radio-options-citizenship"
                    :disabled="viewMode"
                ></b-form-radio-group>
            </div>
            <div class="col-4">
                <p class="font-size-h6">
                    {{ $t("OFFENDER.DETAILS.PERSON.HEADER") }}
                </p>
                <b-form-radio-group
                    size="lg"
                    v-model="selectedPerson"
                    :options="person"
                    name="radio-options-person"
                    :disabled="viewMode"
                ></b-form-radio-group>
            </div>
        </div>

        <div v-if="selectedCitizenship === 'foreigner'">
            <foreigner-offender-form
                v-if="selectedPerson === 'citizen'"
                :item="item"
                ref="foreignerOffender"
                :viewMode="viewMode"
                :selectedPerson="selectedPerson"
            >
            </foreigner-offender-form>
            <foreigner-legal-offender-form
                v-if="selectedPerson === 'legal_entity'"
                :item="item"
                ref="foreignerOffender"
                :viewMode="viewMode"
                :selectedPerson="selectedPerson"
            >
            </foreigner-legal-offender-form>
        </div>
        <div v-else>
            <citizen-offender-form
                v-if="selectedPerson === 'citizen'"
                :item="selectedOffender"
                ref="citizenOffender"
                :viewMode="viewMode"
            >
            </citizen-offender-form>
            <legal-offender-form
                v-if="selectedPerson === 'legal_entity'"
                ref="legalOffender"
                :item="selectedOffender"
                :viewMode="viewMode"
            >
            </legal-offender-form>
        </div>

        <div class="mt-6">
            <slot name="buttons" :submit="submit" :cancel="cancel" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CitizenOffenderForm from "./CitizenOffenderForm";
import ForeignerOffenderForm from "./ForeignerOffenderForm";
import LegalOffenderForm from "./LegalOffenderForm";
import ForeignerLegalOffenderForm from "./ForeignerLegalOffenderForm";

import { PersonModel } from "@/modules/control/models/person-model";

const { fields } = PersonModel;

export default {
    name: "OffenderFormTypes",
    components: {
      CitizenOffenderForm,
      ForeignerOffenderForm,
      LegalOffenderForm,
      ForeignerLegalOffenderForm
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
        },
    },
    data() {
        return {
            citizenship: [
                {
                    text: this.$t("OFFENDER.DETAILS.CITIZENSHIP.ROMANIAN"),
                    value: "citizen",
                },
                {
                    text: this.$t("OFFENDER.DETAILS.CITIZENSHIP.FOREIGNER"),
                    value: "foreigner",
                },
            ],
            selectedCitizenship: "citizen",
            selectedPerson: "citizen",
            fields,
            selectedOffender: null,
            fv: null,
        };
    },
    watch: {
        selectedPerson(value) {
            if (!this.item || this.item?.user_type_name !== value) {
                this.selectedOffender = undefined;
            }
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
            record: "control/form/record",
        }),
        person() {
            const options = [{
                text: this.$t("OFFENDER.DETAILS.PERSON.INDIVIDUAL"),
                value: "citizen",
            },
            {
                text: this.$t("OFFENDER.DETAILS.PERSON.LEGAL"),
                value: "legal_entity",
            }];

            return options;
        }
    },
    created() {
        this.selectedOffender = this.item;
    },
    mounted() {
        if (this.viewMode || this.item) {
            this.selectedPerson = this.item.selectedPerson;
            this.selectedCitizenship = this.item.user_type_name !== "foreigner" ? "citizen" : "foreigner";
        }
    },
    methods: {
        async submit() {
            const data = {
                offender: this.selectedOffender,
                selectedPerson: this.selectedPerson
            }

            this.$emit("submit", data);
        },
        cancel() {
            this.$emit("cancel");
        }
    },
};
</script>
