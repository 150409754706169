<template>
    <div class="form-group" id="st_offender_section">
        <OffenderFormTypes
            ref="offender"
            :viewMode="viewMode"
            :item="selectedEntity"
            @submit="submit"
            @cancel="cancel"
        >
            <template #buttons="{ submit, cancel }">
                <div class="d-flex justify-content-between">
                    <st-button size="large" variant="link" :callback="cancel">
                        {{ $t('CONTROL.MODALS.CANCEL') }}
                    </st-button>
                    <st-button size="large" :callback="submit">
                          {{ submitButtonText }}
                    </st-button>
                </div>
            </template>
        </OffenderFormTypes>
    </div>
</template>

<script>
import OffenderFormTypes from "./OffenderFormTypes";
import { mapGetters } from "vuex";
import { generateRandomId } from '@/core/helpers/globalMethods';

export default {
    name: "OffenderForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false
        },
        items: {
            type: Array,
        },
    },
    data() {
        return {
            selectedEntity: null
        };
    },
    components: {
        OffenderFormTypes,
    },
    computed: {
        ...mapGetters({
            record: "control/form/record",
        }),
        submitButtonText() {
            return this.item ? this.$t('CONTROL.MODALS.SAVE') : this.$t('CONTROL.MODALS.ADD');
        },
    },
    methods: {
        onModelChange(model) {
            this.$emit("change", model);
        },
        async validateData() {
            const fieldRefs = this.$refs.offender.$refs;
            const selectedEntityKey = Object.keys(fieldRefs).find(
                (key) => !!fieldRefs[key]
            );
            const selectedEntity = fieldRefs[selectedEntityKey];

            let status = await selectedEntity.fv.validate();
            let details = this.fillOffenderModel(selectedEntity);

            const offender_id = this.item?.offender_id || generateRandomId();
            const offenderDetails = { ...selectedEntity.model, ...details, offender_id };
            const isPersonalNumericCodeUsed = this.checkUniquePersonalNumericCode(this.items, offenderDetails);
            const isPassportUsed = this.checkPassport(this.items, offenderDetails);
            if (isPersonalNumericCodeUsed) {
                this.$alert({
                    type: 'warning',
                        text: this.$t('CONTROL.NOTIFIERS.DUPLICATE_WITNESS_PNC', {pnc: offenderDetails.personal_numeric_code}),
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: this.$t('GENERAL.BUTTON.UPDATE'),
                    });
                return { status: 'Invalid'};
            }

            if (isPassportUsed) {
                this.$alert({
                    type: 'warning',
                        text: this.$t('CONTROL.NOTIFIERS.DUPLICATE_OFFENDER_PASSPORT', {passport: offenderDetails.passport_number}),
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: this.$t('GENERAL.BUTTON.UPDATE'),
                    });
                return { status: 'Invalid'};
            }

            return {
                status,
                offenderDetails,
            };
        },
        fillOffenderModel(entity) {
            if (entity.model.user_type_name === "foreigner") {
                return {};
            }

            let details = {
                locality: entity.fields.locality_id.options.find((el) => el.value === entity.model.locality_id)?.text || "",
                county: entity.fields.county_id.options.find((el) => el.value === entity.model.county_id)?.text || "",
            };

            if (entity.model.user_type_name === "citizen") {
                details.village = entity.fields.village_id.options.find((el) => el.value === entity.model.village_id)?.text || "";
            }

            return details;
        },
        async submit(data) {
            const offender = await this.validateData();
            
            if (offender.status === 'Valid') {
                offender.offenderDetails.selectedPerson = data.selectedPerson;

                if (this.item) {
                    offender.index = this.item.index;
                }

                this.$emit("submitForm", offender);
            }
        },
        cancel() {
            this.$emit("cancelForm");
        },
        checkUniquePersonalNumericCode(offenders, offenderDetails) {
            return offenders
                .filter(el => el.offender_id !== offenderDetails.offender_id)
                .some((el) => offenderDetails.personal_numeric_code && el.personal_numeric_code === offenderDetails.personal_numeric_code);
        },
        checkPassport(offenders, offenderDetails) {
            return offenders
                .filter(el => el.offender_id !== offenderDetails.offender_id)
                .some((el) => offenderDetails.passport_number && el.passport_number === offenderDetails.passport_number);
        }
    },
    created() {
        this.selectedEntity = this.item || null;
    },
};
</script>
