<template>
    <form class="form"
        novalidate="novalidate"
        id="st_foreigner_form"
    >
        <div class="row mb-6">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.passport_number.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.passport_number.name]"
                            :ref="fields.passport_number.name"
                            :name="fields.passport_number.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.id_card_release_date.label }}
                    </label>
                    <div>
                        <st-datepicker
                            v-model="model[fields.id_card_release_date.name]"
                            :ref="fields.id_card_release_date.name"
                            :name="fields.id_card_release_date.name"
                            :disabled="viewMode"
                            :max="max"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.last_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.last_name.name]"
                            :ref="fields.last_name.name"
                            :name="fields.last_name.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.first_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.first_name.name]"
                            :ref="fields.first_name.name"
                            :name="fields.first_name.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.company_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.company_name.name]"
                            :ref="fields.company_name.name"
                            :name="fields.company_name.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.unique_registration_code.label }} *
                            </label>
                            <div>
                                <st-input-text
                                    v-model="model[fields.unique_registration_code.name]"
                                    :ref="fields.unique_registration_code.name"
                                    :name="fields.unique_registration_code.name"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.registration_number.label }}
                            </label>
                            <div>
                                <st-input-text
                                    v-model="model[fields.registration_number.name]"
                                    :ref="fields.registration_number.name"
                                    :name="fields.registration_number.name"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.person_type.label }}
                    </label>
                    <b-form-select
                        v-model="model[fields.person_type.name]"
                        :options="personType"
                        :ref="fields.person_type.name"
                        :name="fields.person_type.name"
                    >
                        <template #first>
                            <b-form-select-option
                                :value="null"
                                disabled
                            >
                                {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ $t('SET_PROFILE_DATA.FOREIGNER.COUNTRY') }} 
                            </label>
                            <div>
                                <st-autocomplete
                                    v-model="model[fields.country.name]"
                                    :options="fields.country.options"
                                    :ref="fields.country.name"
                                    :name="fields.country.name"
                                    value-field="text"
                                    text-field="text"
                                    :disabled="viewMode"
                                ></st-autocomplete>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.city.label }}
                            </label>
                            <div>
                                <st-input-text
                                    v-model="model[fields.city.name]"
                                    :ref="fields.city.name"
                                    :name="fields.city.name"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.address.label }}
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.address.name]"
                            :ref="fields.address.name"
                            :name="fields.address.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.email.label }}
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.email.name]"
                            :ref="fields.email.name"
                            :name="fields.email.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.phone_number.label }}
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.phone_number.name]"
                            :ref="fields.phone_number.name"
                            :name="fields.phone_number.name"
                            :disabled="viewMode"
                            phone-validation
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.contravention_series.label }}
                            </label>
                            <div>
                                <st-input-text
                                    v-model="model[fields.contravention_series.name]"
                                    :ref="fields.contravention_series.name"
                                    :name="fields.contravention_series.name"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.contravention_number.label }}
                            </label>
                            <div>
                                <st-input-text
                                    v-model="model[fields.contravention_number.name]"
                                    :ref="fields.contravention_number.name"
                                    :name="fields.contravention_number.name"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label>
                        {{ fields.objections.label }}
                    </label>
                    <b-form-textarea
                        v-model="model[fields.objections.name]"
                        :ref="fields.objections.name"
                        :name="fields.objections.name"
                        :placeholder="fields.objections.placeholder"
                    ></b-form-textarea>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { PersonModel } from "@/modules/control/models/person-model";
import { FormSchema } from '@/shared/form/form-schema';
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { idCard } from '@/shared/utils/id-card';

const { fields, personType } = PersonModel;

const formSchema = new FormSchema([
    fields.id,
    fields.user_type_name,
    fields.passport_number,
    fields.first_name,
    fields.last_name,
    fields.country,
    fields.city,
    fields.address,
    fields.email,
    fields.phone_number,
    fields.objections,
    fields.contravention_series,
    fields.contravention_number,
    fields.id_card_release_date,
    fields.unique_registration_code,
    fields.registration_number,
    fields.company_name,
    fields.person_type
]);

export default {
    name: 'ForeignerLegalOffenderForm',
    components: {},
    props: {
        viewMode: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object
        },
        selectedPerson: {
            type: String,
            default: 'citizen'
        }
    },
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            max: idCard.releaseDate,
            personType,
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            localities: 'profile/settlements',
            villages: 'profile/villages',
            record: "control/form/record"
        })
    },
    async created() {
        const record = this.getInitialModelValues();
        this.model = formSchema.initialValues(record);
        
        if (!this.viewMode) {
            this.model.user_type_name = 'foreigner';
        }
    },
    mounted() {
        this.fv = createFormValidation('st_foreigner_form', this.rules);
    },
    methods: {
        getInitialModelValues() {
            const userType = {
                user_type_name: 'foreigner'
            }

            if ((this.viewMode || (this.record?.status === 'draft')) && this.item) {
                return {
                    ...this.item,
                };
            } else {
                if (this.item) {
                    return { ...this.item, ...userType};
                }

                return userType;
            }
        },
    }
}
</script>
